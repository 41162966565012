import axios from "axios";
import { Decrypt, Encrypt, decode } from "@/utils/AES";
let AES_Urls = ["/user/login"];

let RSA_publicKey = "";
let lastUpdateTimeOfToken = 0;
let fromToken = "";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API || "",
  timeout: 10000,
});

let encryptionFlag =
  window.location.href.indexOf("disableaes=1") > -1 ? false : true; // disableaes=1时就不加密，没有或者为0都加密
// 添加请求拦截器
service.interceptors.request.use(
  function (config) {
    //config.data = qs.stringify(config.data) // 转为formdata数据格式
    if (config.method == "get") {
      // 请求参数加密代码  目前get请求暂时不加密
      if (AES_Urls.indexOf(config.url) != -1 && encryptionFlag) {
        config.headers["Content-Type"] = "application/text";
        let { cipherText, key } = Encrypt(
          JSON.stringify(config.data),
          decode(RSA_publicKey)
        );
        config.headers["cek-k"] = key;
        // config.url+='?'+cipherText;
        // config.params = cipherText;
        // config.data = cipherText;
        config.params = config.data;
      } else {
        config.params = config.data;
      }

      // 不加密
      // config.params = config.data;
    } else {
      if (AES_Urls.indexOf(config.url) != -1 && encryptionFlag) {
        config.headers["Content-Type"] = "application/text";
        console.log("decode", RSA_publicKey);
        let { cipherText, key } = Encrypt(
          JSON.stringify(config.data),
          decode(RSA_publicKey)
        );
        config.headers["cek-k"] = key;
        // config.params = cipherText;
        config.data = cipherText;
      }
    }

    if (fromToken) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      // config.headers['x-token'] = getToken()
      config.headers["form-token"] = fromToken;
      // console.log(`${config.url}token请求为===> ${store.getters.fromToken}`);
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    
    let res = response;
    if (response && response.headers['cek-s'] == 1) {
        res = JSON.parse(Decrypt(response.data));
    } else {
        res = response.data;
    }

    if (response && response.headers["cek-p"]) {
      RSA_publicKey = response.headers["cek-p"];
    }

    if (response && response.headers["form-token"]) {
      const token = response.headers["form-token"];
      if (token.includes(".")) {
        const time = token.split(".")[1];
        if (time * 1 > lastUpdateTimeOfToken) {
          lastUpdateTimeOfToken = time;
          fromToken = token;
        }
      } else {
        fromToken = token;
      }
    }

    if (res.code !== 1) {
      console.log("==========> ", response.config);

      // 参数中noNotifyError为true时，不弹出错误提示
      if (
        response.config?.data?.noNotifyError ||
        response.config?.data?.indexOf("noNotifyError") >= 0
      ) {
        return res;
      }

      if (
        res.code === 1038 ||
        res.code === 1040 ||
        res.code === 20001 ||
        res.code === 10050
      ) {
        return res;
      }

      if (res.code == 10056) {
        // store.dispatch('app/getEncryptKey');
        return Promise.reject(res);
      }

      if (res && res.data && res.data.countdown) {
        return res;
      }
      if (res && res.data && res.data.is_done === 1) {
        return res;
      }

      // 根据状态码 1011 判定为要输入验证码，且验证码在data里面直接返回
      if (res.code === 1011 || res?.data?.captcha) {
        return Promise.reject(new Error(res ? JSON.stringify(res) : "Error"));
      }

      return Promise.reject(res);
    }

    return res;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default service;
