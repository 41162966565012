import JSEncrypt from 'jsencrypt'

// 公钥
//const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC/qtdtnjxoKndOhsfqSNSM8HKV1WI8MMQvDE1M8IUo+YCtu6hqLprVAIxwhZOHkH0ezY/3pcQM0h7BeeE5RBsg5K6nHfWmUP3F/cs0m32STlAj60WBboIHvxYFn499xWeG37u08P+/HtAi8Pqc/kKJd8nkDsuXmxdaOFmRX0bIrQIDAQAB"

// 私钥
//const privateKey = "ptivateKey"

export const setRSApass = (password,publicKey) => {
    let jse = new JSEncrypt()
    jse.setPublicKey(publicKey)
    // 加密内容
    let encrypted = jse.encrypt(password)
    return encrypted
};

export const getRSApass = (password) => {
    let jse = new JSEncrypt();
    jse.setPrivateKey(privateKey)
    // 解密内容
    let decrypt = jse.decrypt(password)
    return decrypt
};

