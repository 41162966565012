import request from './request';

// 获取加密公钥
export function getEncryptKey(data) {
    return request({
        url: '/index/encryptKey',
        method: 'get',
        data,
        noLoading:true,
    })
}

export function login(data) {
    return request({
        url: '/user/login',
        method: 'post',
        data
    })
}

// 获取验证码
export function getCaptcha() {
    return request({
        url: '/index/getcaptcha',
        method: 'get',
    })
}