const CryptoJS = require('crypto-js');  //引用AES源码js
import { setRSApass } from './RSA.js'
// import store from '@/store'
// const Base64 = require('js-base64').Base64 // 引入Base64


const privateKey = generateHexCode();
const key = CryptoJS.enc.Utf8.parse(privateKey);  //十六位十六进制数作为密钥
const iv = CryptoJS.enc.Utf8.parse('ABCDEF1234123412');   //十六位十六进制数作为密钥偏移量


function encode(str_p) {
    let strlen = str_p.length;
    let encode_str = '';
    let i = 0;
    let j = 2;

    while (i < strlen) {
        let tmp = str_p.substr(i, j);
        encode_str += tmp.split('').reverse().join('');
        i = i + j;
        j++;
        if (j > 8) {
            j = 2;
        }
    }
    return btoa(encode_str);
}
export function decode(str_p) {
    var str = atob(str_p);
    var strlen = str.length;

    var decode_str = '';
    var i = 0;
    var j = 2;
    while (i < strlen) {
        var tmp = str.substr(i, j);
        decode_str += tmp.split('').reverse().join('');
        i = i + j;
        j++;
        if (j > 8) {
            j = 2;
        }
    }
    return decode_str;
}


// 生成密钥
function generateHexCode() {
    let hexCode = '';
    const characters = '0123456789ABCDEF';
    for (let i = 0; i < 16; i++) {
        const randomIndex = Math.floor(Math.random() * 16);
        hexCode += characters[randomIndex];
    }
    return hexCode;
}


//   加密密钥
function encryptKey(publicKey) {

    if (!publicKey) {
        console.error(' RSA publicKey is Empty');
        return false;
    }
    return setRSApass(privateKey, publicKey);

}

//解密方法
export function Decrypt(word) {
    var decrypt = CryptoJS.AES.decrypt(word, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
    return CryptoJS.enc.Utf8.stringify(decrypt).toString()
}

//加密方法
export function Encrypt(word, publicKey) {
    let srcs = CryptoJS.enc.Utf8.parse(word);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    return {
        cipherText: encrypted.toString(),
        key: encode(encryptKey(publicKey))
    }
}

//DES  ECB模式加密
export function DecryptLocal(word, key) { // 解密方法
    const enKey = CryptoJS.enc.Utf8.parse(key)
    const encryptedHexStr = CryptoJS.enc.Hex.parse(word)
    const srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr)
    const decrypt = CryptoJS.AES.decrypt(srcs, enKey, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    })
    const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
    return decryptedStr.toString()

}

//DES  ECB模式加密
export function Encryptlocal(word, key) { // 加密方法
    const enKey = CryptoJS.enc.Utf8.parse(key)
    const srcs = CryptoJS.enc.Utf8.parse(word)
    const encrypted = CryptoJS.AES.encrypt(srcs, enKey, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    })
    return encrypted.ciphertext.toString().toUpperCase()
}

export const keyw = 'a1q2e3f4d5x6y7u8';