<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { getEncryptKey } from '@/api/index'
export default {
  name: 'App',
  created() {
    getEncryptKey().then(res => {
      console.log(res);
    })
  }
}
</script>

<style>

</style>
